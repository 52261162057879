.day-logger {

  ul {
    list-style: none;
    text-align: left;
    max-width: 325px;
    margin: 1rem auto;
    padding-left: 0;

    li {
      position: relative;
      border-top: 1px solid rgba(255, 255, 255, 0.3);

      &:last-of-type {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }

      label {
        display: block;
      }

      .checkBox {
        width: 100%;
        height: 60px;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: color 400ms ease;
        
        input {
          display: none;

          &:checked + .box {
            background-color: #b3ffb7;
            
            &:after {
              top: 0;
            }
          }
        }
        
        .box {
          width: 50%;
          height: 50%;
          transition: all 1.1s cubic-bezier(.19,1,.22,1);
          border: 2px solid transparent;
          background-color: #ccc;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          box-shadow: 0 5px rgba(0,0,0,.2);
          position: absolute;
          left: 0;
          max-width: 30px;
          transition: background 400ms ease;

          &:after {
            width: 50%;
            height: 20%;
            content: '';
            position: absolute;
            border-left: 7.5px solid;
            border-bottom: 7.5px solid;
            border-color: #40c540;
            transform: rotate(-45deg) translate3d(0,0,0);
            transform-origin: center center;
            transition: all 1.1s cubic-bezier(.19,1,.22,1);
            left: 0;
            right: 0;
            top: 200%;
            bottom: 5%;
            margin: auto;
          }
        }

        &:hover {
          // color: #3b8be6;

          input {

            &:not(:checked) + .box {
              background-color: #3b8be6; 
            }
          }
        }
      }
    }
  }

  .date-switcher {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    h4 {
      margin: 0 2rem;
    }
  }
}
