.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
  }

  nav {
    border-bottom: 0;
  }

  p {
    text-align: center;
  }

  button {

    &.google {
      background-color: #db3236;

      &:hover {
        background-color: darken(#db3236, 10%);
      }
    }

    &.twitter {
      background-color: #2c8dd0;

      &:hover {
        background-color: darken(#2c8dd0, 10%);
      }
    }

    &.github {
      background: #333;

      &:hover {
        background-color: darken(#333, 10%);
      }
    }

    > svg {
      margin-left: 0.25rem;
    }
  }
}
