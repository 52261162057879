
.habit-creator {
  margin: 2rem auto;

  // > section {
  //   transition: visibility 400ms ease, opacity 400ms ease, height 400ms ease;

  //   &.closed {
  //     visibility: hidden;
  //     opacity: 0;
  //     height: 0;
  //   }

  //   &.open {
  //     visibility: visible;
  //     opacity: 1;
  //     height: auto;
  //   }
  // }

  ul {
    list-style: none;
    padding-left: 0;

    fieldset {
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      margin-bottom: 0.5rem;
    }
  }
}
